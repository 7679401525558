import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getContent } from "../../util/apicalls";
import { withStyles } from "@material-ui/core/styles";
import { updateContent } from "../../util/apicalls";
import Loader from "../Alert/Loader";
import OneColumnContainer from "../OneColumnContainer";
import Paper from "@material-ui/core/Paper";
import Popup from "../Alert/Popup";
import Editor from "../Editor/Editor";
import Button from "@material-ui/core/Button";
import { isLoggedIn, redirect } from "../../util/apicalls";

const styles = (theme) => ({
  paperInEdit: {
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
    boxShadow: "none",
  },
  root: {
    marginTop: theme.spacing(1),
  },
  rootInEdit: {
    backgroundColor: "white",
  },
  row: {
    paddingTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
  },
  edit: {
    textAlign: "right",
    paddingRight: theme.spacing(1),
  },
});

class EditContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      random: 1,
      openAlert: false,
      data: {
        title: "",
        description: "",
        content: [],
      },
    };

    this.editModeCallback = this.editModeCallback.bind(this);
    this.saveCallback = this.saveCallback.bind(this);
    this.editorCallback = this.editorCallback.bind(this);
    this.handleAddRow = this.handleAddRow.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.loadData = this.loadData.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.setContentPath = this.setContentPath.bind(this);
    this.undo = this.undo.bind(this);
  }

  componentDidMount() {
    if (!isLoggedIn()) {
      redirect("/login");
    }

    this.setContentPath(window.location.pathname);
    this.loadData(window.location.pathname);
  }

  setContentPath() {
    var route = window.location.pathname;

    var splittedRoute = route.substring(1, route.lastIndexOf("/"));
    this.setState({ route: splittedRoute });
  }

  closePopup() {
    this.setState({ openAlert: false });
  }

  loadData(route) {
    var defaultData = {
      title: "",
      description: "",
      content: [],
    };

    this.setState({ isLoading: true, data: defaultData });

    var splittedRoute = route.substring(1, route.lastIndexOf("/"));

    getContent(splittedRoute)
      .then((response) => {
        if (response.data.content == null) {
          response.data.content = [
            { leftContent: { ops: [] }, rightContent: { ops: [] } },
          ];
        }

        this.setState({
          data: {
            title: response.data.title,
            description: response.data.description,
            content: JSON.parse(response.data.content),
          },
          isLoading: false,
        });
      })
      .catch((response) => {
        this.setState({ openAlert: true });
      });
  }

  editModeCallback() {
    this.setState({ isEditMode: true });
  }
  saveCallback() {
    this.setState({ isLoading: true, isEditMode: false });

    updateContent(this.state.route, this.state.data.content).then((result) => {
      this.setState({ isLoading: false });
      redirect(`/${this.state.route}`);
    });
  }

  undo() {
    redirect(`/${this.state.route}`);
  }

  handleAddRow(index) {
    var data = this.state.data;
    data.content.splice(index, 0, {
      leftContent: { ops: [] },
      rightContent: { ops: [] },
    });
    this.setState({ data: data, random: this.state.random + 1 });
  }
  handleRemoveRow(index) {
    var data = this.state.data;
    data.content.splice(index, 1);
    this.setState({ data: data, random: this.state.random + 1 });
  }

  editorCallback(data) {
    var stateData = this.state.data;
    if (data.column === 0) {
      stateData.content[data.row].leftContent = data.data;
    } else if (data.column === 1) {
      stateData.content[data.row].rightContent = data.data;
    }

    this.setState({ data: stateData });
  }

  render() {
    return (
      <OneColumnContainer>
        <Grid container>
          <Grid item xs={12}>
            <Loader isLoading={this.state.isLoading} />
            <Typography variant="h1">{this.state.data.title}</Typography>
          </Grid>
          <Popup
            message="Innehållet gick inte att ladda"
            open={this.state.openAlert}
            onClosing={this.closePopup}
          />
        </Grid>
        <Paper className={this.props.classes.paperInEdit}>
          {this.state.data.content &&
            this.state.data.content.map(function (item, index) {
              return (
                <Grid
                  key={`${index}-${this.state.random}`}
                  container
                  spacing={24}
                  justify="center"
                  alignItems="stretch"
                  className={this.props.classes.rootInEdit}
                >
                  <Grid item xs={12} className={this.props.classes.row}>
                    <Editor
                      text={item.leftContent}
                      row={`${index}-${this.state.random}`}
                      column="0"
                      onChange={this.editorCallback}
                    />
                  </Grid>
                </Grid>
              );
            }, this)}
        </Paper>
        <Grid container>
          <Grid item xs={12} className={this.props.classes.edit}>
            <Button
              variant="contained"
              color="primary"
              className={this.props.classes.button}
              onClick={this.saveCallback}
            >
              Spara
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={this.props.classes.button}
              onClick={this.undo}
            >
              Ångra
            </Button>
          </Grid>
        </Grid>
      </OneColumnContainer>
    );
  }
}

export default withStyles(styles)(EditContent);
